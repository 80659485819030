<template>
  <div class='right-side-bg' :style="{ backgroundColor: theme?.ui_elements?.background?.color }">
    <v-card :class="[{ 'lf-shadow': theme?.ui_elements?.background?.formShadow }]"
      :rounded="computedRadius(theme?.ui_elements?.background?.form_border_radius)" variant="flat"
      class="lf-form__wrapper">
      <!-- PROGRESS BAR TOP-->
      <div
        v-if="theme?.ui_elements?.step_progress?.progressPosition === 'Top' && theme?.ui_elements?.step_progress?.showProgress">
        <v-progress-linear striped :color="this.theme?.ui_elements?.step_progress?.config?.fill_color"
          :bg-color="this.theme?.ui_elements?.step_progress?.config?.stroke_color" model-value="80" bg-opacity="0.7"
          height="18" :class="[
            { 'no-animation': !theme?.ui_elements?.step_progress?.showAnimation },
            'custom-progress-bar'
          ]">
        </v-progress-linear>
      </div>
      <!-- FORM STARTS-->
      <v-form class="formPadding">
        <!-- show these elements when "themes" || "theme-settings" || "typography" || "step-navigation" tab selected -->
        <div
          v-if="pageTabs === 'typography' || pageTabs === 'themes' || pageTabs === 'theme-settings' || pageTabs === 'step-navigation'">
          <!-- TEXT ELEMENT-->
          <div class="lf-form__element">
            <h3 :style="getTypographyStyles('text_box')">This is a text element.</h3>
          </div>
          <!-- QUESTION ONE-->
          <div class="lf-form__question">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              What is the title of the question?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-text-field persistent-hint hint="This is a validation message" value="question answer"
              placeholder="question placeholder" class="question-input"
              :rounded="computedRadius(inputStyles?.border?.radius)"
              :density="computedHeight(inputStyles?.input?.height)"
              :variant="inputStyles?.border?.skin === 'all' ? 'outlined' : 'underlined'"></v-text-field>
          </div>
          <!-- QUESTION TWO-->
          <div class="lf-form__question">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              Which Platform(s) will the app will be build on ?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-radio-group inline v-model="radioModel.button">
              <div class="w-100 d-flex flex-wrap flex-column justify-start">
                <v-btn v-for="(os, n) in ['Android', 'Windows']" :key="n" @click="radioModel.button = os"
                  class="choice-btn d-flex justify-start align-center"
                  :class="{ 'selected': os === radioModel.button, 'mb-4': n === 0 }"
                  :rounded="computedRadius(theme?.ui_elements?.radio_checkbox?.radius)" variant="flat"
                  :height="theme?.ui_elements?.radio_checkbox?.font?.line_height" min-height="40"
                  :color="theme?.ui_elements?.radio_checkbox?.default_style?.backgroundcolor">
                  <span class="text-left w-100 text-none" :style="{
                    whiteSpace: 'break-spaces',
                    fontSize: theme?.ui_elements?.radio_checkbox?.font?.size + 'px',
                    fontFamily: theme?.general?.font?.family
                  }">
                    {{ os }}
                  </span>
                </v-btn>
              </div>
            </v-radio-group>
          </div>
        </div>
        <!-- show these elements when "ui-elements" tab selected  | except for scale -->
        <div v-if="pageTabs === 'ui-elements' && uiElementTabs !== 'scale'">
          <!-- QUESTION SAME | for SELECT-->
          <div v-if="uiElementTabs === 'select'" class="lf-form__question">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              Which Platform(s) will the app will be build on ?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-radio-group inline v-model="radioModel.button">
              <div class="w-100 d-flex flex-wrap flex-column justify-start">
                <v-btn v-for="(os, n) in ['Android', 'Windows']" :key="n" @click="radioModel.button = os"
                  class="choice-btn d-flex justify-start align-center"
                  :class="{ 'selected': os === radioModel.button, 'mb-4': n === 0 }"
                  :rounded="computedRadius(theme?.ui_elements?.radio_checkbox?.radius)" variant="flat"
                  :height="theme?.ui_elements?.radio_checkbox?.font?.line_height" min-height="40"
                  :color="theme?.ui_elements?.radio_checkbox?.default_style?.backgroundcolor">
                  <v-radio :base-color="theme?.ui_elements?.radio_checkbox?.default_style?.border?.color"
                    :color="theme?.ui_elements?.radio_checkbox?.active_style?.border?.color" :value="os"></v-radio>
                  <span class="text-left w-100 text-none" :style="{
                    whiteSpace: 'break-spaces',
                    fontSize: theme?.ui_elements?.radio_checkbox?.font?.size + 'px',
                    fontFamily: theme?.general?.font?.family
                  }">
                    {{ os }}
                  </span>
                </v-btn>
              </div>
            </v-radio-group>
          </div>

          <!-- QUESTION DIFF | for Image/Icon-->
          <div v-if="uiElementTabs === 'choiceImage'" class="lf-form__question">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              Which Platform(s) will the app will be build on ?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-radio-group inline v-model="radioModel.icon">
              <div class="d-flex flex-wrap ga-4 mx-auto">
                <v-card class="cursor-pointer icon-card" :class="{ 'selected': platformData.name === radioModel.icon }"
                  :color="imageIconStyles?.defaultStyle?.backgroundColor"
                  :rounded="computedRadius(imageIconStyles?.defaultStyle?.border?.radius)" width="150" v-for="(platformData, n) in [
                    { name: 'Android', icon: 'mdi-android', desc: 'This is image/icon description.' },
                    { name: 'Windows', icon: 'mdi-microsoft-windows', desc: 'This is image/icon description.' },
                    { name: 'Mac', icon: 'mdi-apple', desc: 'This is image/icon description.' },
                  ]" :key="n" @click="radioModel.icon = platformData.name">
                  <v-card-item class="position-relative align-center text-center card-item">
                    <!-- Checkmark Icon only on Windows -->
                    <v-icon v-if="platformData.name === radioModel.icon" size="small"
                      class="position-absolute top-0 right-0 ma-2 choice-selected-icon"
                      :color="theme?.ui_elements?.choice?.image_icon_skin?.tickbox?.color">
                      mdi-check-circle
                    </v-icon>
                    <!-- Icon -->
                    <!-- Material Design Icon (MDI) -->
                    <v-icon :icon="platformData.icon" class="choice-icon" :style="{
                      '--icon-size': imageIconStyles?.defaultStyle?.iconSize + 'px' || '45px',
                    }"></v-icon>
                    <!-- Label -->
                    <v-card-text class="pa-0 my-2 choice-label">
                      {{ platformData.name }}
                    </v-card-text>
                    <!-- Description -->
                    <v-card-text class="choice-description">
                      {{ platformData.desc }}
                    </v-card-text>
                  </v-card-item>
                </v-card>
              </div>
            </v-radio-group>
          </div>
          <!-- QUESTION DIFF | for Others-->
          <div v-if="uiElementTabs !== 'selchoiceImageect'" class="lf-form__question">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              Which Platform(s) will the app will be build on ?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-radio-group inline v-model="radioModel.button">
              <div class="w-100 d-flex flex-wrap flex-column justify-start">
                <v-btn v-for="(os, n) in ['Android', 'Windows']" :key="n" @click="radioModel.button = os"
                  class="choice-btn d-flex justify-start align-center"
                  :class="{ 'selected': os === radioModel.button, 'mb-4': n === 0 }"
                  :rounded="computedRadius(theme?.ui_elements?.radio_checkbox?.radius)" variant="flat"
                  :height="theme?.ui_elements?.radio_checkbox?.font?.line_height" min-height="40"
                  :color="theme?.ui_elements?.radio_checkbox?.default_style?.backgroundcolor">
                  <span class="text-left w-100 text-none" :style="{
                    whiteSpace: 'break-spaces',
                    fontSize: theme?.ui_elements?.radio_checkbox?.font?.size + 'px',
                    fontFamily: theme?.general?.font?.family
                  }">
                    {{ os }}
                  </span>
                </v-btn>
              </div>
            </v-radio-group>
          </div>
          <!-- QUESTION ONE-->
          <div class="lf-form__question">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              Which Platform(s) will the app will be build on ?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-radio-group :model-value="radioModel.radio">
              <div class="w-100 d-flex flex-wrap flex-column">
                <v-radio class="radio-choices" v-for="(radioOption, n) in ['Android', 'Windows', 'Mac']" :key="n"
                  :value="radioOption" :base-color="theme?.ui_elements?.radio_checkbox?.default_style?.border?.color"
                  :color="theme?.ui_elements?.radio_checkbox?.active_style?.border?.color">
                  <template v-slot:label>
                    <span :style="{
                      color: theme?.ui_elements?.radio_checkbox?.default_style?.color,
                      fontSize: theme?.ui_elements?.radio_checkbox?.font?.size + 'px',
                      fontFamily: theme?.general?.font?.family
                    }">
                      {{ radioOption }}
                    </span>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </div>
          <!-- QUESTION TWO-->
          <div class="lf-form__question">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              Which Platform(s) will the app will be build on ?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-radio-group inline :model-value="radioModel.checkbox">
              <div class="w-100 d-flex flex-wrap flex-row">
                <v-radio class="checkbox-radio" v-for="(checkboxOption, n) in ['Android', 'Windows', 'Mac']" :key="n"
                  :value="checkboxOption" :base-color="theme?.ui_elements?.radio_checkbox?.default_style?.border?.color"
                  :color="theme?.ui_elements?.radio_checkbox?.active_style?.border?.color">
                  <template v-slot:label>
                    <span :style="{
                      color: theme?.ui_elements?.radio_checkbox?.default_style?.color,
                      fontSize: theme?.ui_elements?.radio_checkbox?.font?.size + 'px',
                      fontFamily: theme?.general?.font?.family
                    }">
                      {{ checkboxOption }}
                    </span>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </div>
        </div>
        <!-- show these elements when "ui-elements" tab selected  | for scale -->
        <div v-if="pageTabs === 'ui-elements' && uiElementTabs === 'scale'">
          <!-- QUESTION ONE-->
          <div class="lf-form__question__scale">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              What is your (approximate) monthly marketing budget?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-slider class="d-block" hide-details :thumb-color="theme?.ui_elements?.scale?.selector_color"
              :track-color="theme?.ui_elements?.scale?.config?.stroke_color"
              :track-fill-color="theme?.ui_elements?.scale?.config?.fill_color" thumb-size="25" track-size="10"
              v-model="slider" min="1000" max="10000">
              <template v-slot:prepend>
                <v-row>
                  <v-col align="center" justify="center">
                    <v-text-field active class="text-center" type="number" hide-spin-buttons hide-details prefix="$"
                      min="1000" max="10000" :value="parseInt(slider)" rounded="md" density="comfortable"
                      variant="outlined" style="width: 17%" :color="getTypographyStyles('question_title')?.color"
                      :base-color="getTypographyStyles('question_title')?.color" />
                  </v-col>
                </v-row>
              </template>
            </v-slider>
          </div>
          <!-- QUESTION TWO-->
          <div class="lf-form__question mt-10">
            <h5 v-if="!theme?.ui_elements?.question?.hide_question_labels"
              :style="getTypographyStyles('question_title')">
              What is your (approximate) monthly marketing budget?
              <span v-if="theme?.ui_elements?.question?.asterisk_for_required">*</span>
            </h5>
            <p :style="getTypographyStyles('question_description')">This is the question description.</p>
            <v-radio-group v-model="smileyModel">
              <v-row>
                <v-col v-for="smiley in rangeSmileyText" :key="smiley.value" class="text-center">
                  <!-- Hidden radio input for accessibility -->
                  <v-radio :value="smiley.value" class="d-none"></v-radio>
                  <!-- Icon-based selection -->
                  <div class="cursor-pointer" @click="smileyModel = smiley.value">
                    <v-icon class="smiley-range" size="40" :class="{ 'active': smiley.value === smileyModel }">
                      {{ smiley.icon }}
                    </v-icon>
                    <div class="smiley-text" :class="{ 'active': smiley.value === smileyModel }"
                      :style="{ fontFamily: theme?.general?.font?.family }">
                      {{ smiley.label }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-radio-group>
          </div>
        </div>
        <!-- STEP NAVIGATION | for all tabs-->
        <v-container class="d-flex flex-wrap justify-space-between px-0">
          <!-- Back Button -->
          <div v-if="!navigationStyle?.back?.default?.hide" :style="{
            width: navigationStyle?.back?.default?.hide ? '0%' : '48%',
            textAlign: navigationStyle?.back?.default?.alignment
          }">
            <v-btn class="back-btn" :rounded="computedRadius(navigationStyle?.back?.default?.border?.radius)"
              :height="navigationStyle?.back?.default?.height"
              :variant="navigationStyle?.back?.default?.shadow ? 'elevated' : 'flat'">
              <span>
                {{ navigationStyle?.back?.default?.text }}
              </span>
              <template v-if="navigationStyle?.back?.default?.icon" v-slot:prepend>
                <v-icon class="back-btn-icon" :icon="`md:${navigationStyle?.back?.default?.icon}`"></v-icon>
              </template>
            </v-btn>
          </div>

          <!-- Continue | Submit Button -->
          <div :style="{
            textAlign: isSubmitTab
              ? navigationStyle?.submit?.default?.alignment
              : navigationStyle?.continue?.default?.alignment,
            width: navigationStyle?.back?.default?.hide ? '100%' : '48%'
          }">
            <!-- Submit Button -->
            <v-btn v-if="isSubmitTab" :rounded="computedRadius(navigationStyle?.submit?.default?.border?.radius)"
              :height="navigationStyle?.submit?.default?.height"
              :variant="navigationStyle?.submit?.default?.shadow ? 'elevated' : 'flat'" class="submit-btn">
              <span>
                {{ navigationStyle?.submit?.default?.text }}
              </span>
              <template v-if="navigationStyle?.submit?.default?.icon" v-slot:append>
                <v-icon class="submit-btn-icon" :icon="`md:${navigationStyle?.submit?.default?.icon}`"></v-icon>
              </template>
            </v-btn>

            <!-- Continue Button -->
            <v-btn v-else :rounded="computedRadius(navigationStyle?.continue?.default?.border?.radius)"
              :width="navigationStyle?.continue?.default?.width" :height="navigationStyle?.continue?.default?.height"
              :variant="navigationStyle?.continue?.default?.shadow ? 'elevated' : 'flat'" class="continue-btn">
              <span>
                {{ navigationStyle?.continue?.default?.text }}
              </span>
              <template v-if="navigationStyle?.continue?.default?.icon" v-slot:append>
                <v-icon class="continue-btn-icon" :icon="`md:${navigationStyle?.continue?.default?.icon}`"></v-icon>
              </template>
            </v-btn>
          </div>
        </v-container>
      </v-form>
      <!-- FORM ENDS-->
      <!-- PROGRESS BAR BOTTOM-->
      <div>
        <v-progress-linear
          v-if="theme?.ui_elements?.step_progress?.progressPosition === 'Bottom' && theme?.ui_elements?.step_progress?.showProgress"
          striped :color="this.theme?.ui_elements?.step_progress?.config?.fill_color"
          :bg-color="this.theme?.ui_elements?.step_progress?.config?.stroke_color" model-value="80" bg-opacity="0.7"
          height="18" :class="[
            { 'no-animation': !theme?.ui_elements?.step_progress?.showAnimation },
            'custom-progress-bar'
          ]">
        </v-progress-linear>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: Object,
      required: true
    },
    pageTabs: {
      type: String,
      required: true
    },
    navigationTabs: {
      type: String,
      required: true
    },
    uiElementTabs: {
      type: String,
      required: true
    },
  },
  mounted() {
  },
  data() {
    return {
      slider: 5000,
      rangeSmileyText: [
        {
          value: "veryUnsatisfied",
          label: "Very Unsatisfied",
          icon: "mdi-emoticon-dead-outline",
        },
        {
          value: "unsatisfied",
          label: "Unsatisfied",
          icon: "mdi-emoticon-sad-outline",
        },
        {
          value: "neutral",
          label: "Neutral",
          icon: "mdi-emoticon-neutral-outline"
        },
        {
          value: "satisfied",
          label: "Satisfied",
          icon: "mdi-emoticon-happy-outline",
        },
        {
          value: "verySatisfied",
          label: "Very Satisfied",
          icon: "mdi-emoticon-excited-outline",
        },
      ],
      smileyModel: 'neutral',
      radioModel: {
        button: 'Android',
        radio: 'Windows',
        checkbox: 'Mac',
        icon: 'Mac'
      },
    }
  },
  methods: {
    filterFontFamily: function (family) {
      if (family === 'inherit') {
        return this.websiteFontFamily
      }
      return family
    },
    getTypographyStyles(type) {
      // Early return if no theme or typography settings exist
      if (!this.theme?.typography?.[type]) {
        return {};
      }

      const typographySettings = this.theme.typography[type];

      // Special handling for text_box type
      if (type === 'text_box') {
        return {
          color: typographySettings.color,
          fontFamily: this.filterFontFamily(typographySettings.family),
          textAlign: typographySettings.text_align
        };
      }

      // Handle regular typography types
      return {
        color: typographySettings?.font?.color,
        fontFamily: this.filterFontFamily(typographySettings?.font?.family),
        fontSize: typographySettings?.font?.size ? `${typographySettings?.font?.size}px` : undefined,
        fontWeight: typographySettings?.font?.weight,
        lineHeight: typographySettings?.font?.line_height ? `${typographySettings?.font?.line_height}px` : undefined,
        textAlign: typographySettings?.text_align
      };
    },
    mapBorderRadius(value) {
      const RADIUS_SCALE = {
        0: '0',
        sm: 'sm',
        md: 'md',
        lg: 'lg',
        xl: 'xl',
        pill: 'pill',
        shaped: 'shaped',
      }

      // If value is already a valid semantic key, return it directly
      if (typeof value === 'string' && value in RADIUS_SCALE) {
        return RADIUS_SCALE[value]
      }

      // Convert value to number for comparison
      const numValue = Number(value)

      // Return 0 if invalid number
      if (isNaN(numValue)) return RADIUS_SCALE[0]

      // Map numeric values to semantic scale
      if (numValue === 0) return RADIUS_SCALE[0]
      if (numValue <= 3) return RADIUS_SCALE.sm
      if (numValue <= 9) return RADIUS_SCALE.md
      if (numValue <= 13) return RADIUS_SCALE.lg
      if (numValue <= 20) return RADIUS_SCALE.xl
      return RADIUS_SCALE.pill
    },
    mapInputHeight(value) {
      const HEIGHT_SCALE = {
        default: 'default',
        comfortable: 'comfortable',
        compact: 'compact',
      }

      // If value is already a valid semantic key, return it directly
      if (typeof value === 'string' && value in HEIGHT_SCALE) {
        return HEIGHT_SCALE[value]
      }

      // Convert value to number for comparison
      const numValue = Number(value)
      // Return 0 if invalid number
      if (isNaN(numValue)) return HEIGHT_SCALE.compact

      // Map numeric values to semantic scale
      if (numValue === 0) return HEIGHT_SCALE.compact
      if (numValue <= 30) return HEIGHT_SCALE.compact
      if (numValue <= 55) return HEIGHT_SCALE.comfortable
      if (numValue <= 75) return HEIGHT_SCALE.default
      return HEIGHT_SCALE.default
    },
  },
  computed: {
    websiteFontFamily: function () {
      return window
        .getComputedStyle(document?.body, null)
        .getPropertyValue('font-family')
    },
    isSubmitTab() {
      return this.navigationTabs === 'submitButton'
    },
    warningStyle() {
      return {
        color: this.theme?.general?.colors?.warning_color,
        fontFamily: this.theme?.general?.font?.family,
      }
    },
    inputStyles: function () {
      return {
        font: {
          family: this.theme?.typography?.input_box?.font?.family,
          size: this.theme?.typography?.input_box?.font?.font_size + "px",
          indent: this.theme?.typography?.input_box?.font?.text_intent + "px",
          color: this.theme?.typography?.input_box?.font?.color,
          align: this.theme?.typography?.input_box?.text_align,
        },
        border: {
          style: this.theme?.typography?.input_box?.border?.style,
          stroke: this.theme?.typography?.input_box?.border?.width,
          skin: this.theme?.typography?.input_box?.border?.skin,
          color: this.theme?.typography?.input_box?.border?.color,
          radius: this.theme?.typography?.input_box?.radius,
        },
        input: {
          backgroundColor:
            this.theme?.typography?.input_box?.font?.background_color,
          placeholderColor:
            this.theme?.typography?.input_box?.placeholder?.color,
          height: this.theme?.typography?.input_box?.font?.height,
          spacing: this.theme?.typography?.input_box?.font?.spacing + "px",
        },
      }
    },
    scaleStyle: function () {
      return {
        slider: {
          thumb: this.theme?.ui_elements?.scale?.selector_color,
          track: this.theme?.ui_elements?.scale?.config?.stroke_color,
          trackFill: this.theme?.ui_elements?.scale?.config?.fill_color,
        },
        likert: {
          smileyText: this.theme?.ui_elements?.scale?.smileys_text_color,
          smileyTextActive:
            this.theme?.ui_elements?.scale?.active_style
              ?.smileys_active_text_color,
          smileyColor: this.theme?.ui_elements?.scale?.smileys_color,
          smileyHoverColor:
            this.theme?.ui_elements?.scale?.hover_style?.smileys_hover_color,
          smileyActiveColor:
            this.theme?.ui_elements?.scale?.active_style?.smileys_active_color,
        },
      }
    },
    imageIconStyles: function () {
      return {
        defaultStyle: {
          padding: {
            top: this.theme?.ui_elements?.choice?.image_icon_skin?.padding?.top,
            right:
              this.theme?.ui_elements?.choice?.image_icon_skin?.padding?.right,
            bottom:
              this.theme?.ui_elements?.choice?.image_icon_skin?.padding?.bottom,
            left: this.theme?.ui_elements?.choice?.image_icon_skin?.padding
              ?.left,
          },
          border: {
            width:
              this.theme?.ui_elements?.choice?.image_icon_skin?.border?.width,
            style:
              this.theme?.ui_elements?.choice?.image_icon_skin?.border?.style,
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.border?.color,
            radius:
              this.theme?.ui_elements?.choice?.image_icon_skin?.border?.radius,
          },
          title: {
            fontFamily:
              this.theme?.ui_elements?.choice?.image_icon_skin?.title_font
                ?.family,
            fontSize:
              this.theme?.ui_elements?.choice?.image_icon_skin?.title_font
                ?.size,
            fontWeight:
              this.theme?.ui_elements?.choice?.image_icon_skin?.title_font
                ?.weight,
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.title_font
                ?.color,
            lineHeight:
              this.theme?.ui_elements?.choice?.image_icon_skin?.title_font
                ?.line_height,
          },
          description: {
            fontFamily:
              this.theme?.ui_elements?.choice?.image_icon_skin?.description_font
                ?.family,
            fontSize:
              this.theme?.ui_elements?.choice?.image_icon_skin?.description_font
                ?.size,
            fontWeight:
              this.theme?.ui_elements?.choice?.image_icon_skin?.description_font
                ?.weight,
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.description_font
                ?.color,
            lineHeight:
              this.theme?.ui_elements?.choice?.image_icon_skin?.description_font
                ?.line_height,
          },
          backgroundColor:
            this.theme?.ui_elements?.choice?.image_icon_skin?.background_color,
          shadow: this.theme?.ui_elements?.choice?.image_icon_skin?.shadow,
          iconSize: this.theme?.ui_elements?.choice?.image_icon_skin?.icon_size,
          iconColor:
            this.theme?.ui_elements?.choice?.image_icon_skin?.icon_color,
          imageWidth:
            this.theme?.ui_elements?.choice?.image_icon_skin?.image_width,
          boxWidth: this.theme?.ui_elements?.choice?.image_icon_skin?.width,
          boxHeight: this.theme?.ui_elements?.choice?.image_icon_skin?.height,
        },
        hoverStyle: {
          border: {
            width:
              this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
                ?.border?.width,
            style:
              this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
                ?.border?.style,
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
                ?.border?.color,
          },
          title: {
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
                ?.title_font?.color,
          },
          description: {
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
                ?.description_font?.color,
          },
          shadow:
            this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
              ?.shadow,
          backgroundColor:
            this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
              ?.background_color,
          iconColor:
            this.theme?.ui_elements?.choice?.image_icon_skin?.hover_style
              ?.icon_color,
        },
        activeStyle: {
          border: {
            width:
              this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
                ?.border?.width,
            style:
              this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
                ?.border?.style,
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
                ?.border?.color,
          },
          title: {
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
                ?.title_font?.color,
          },
          description: {
            color:
              this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
                ?.description_font?.color,
          },
          shadow:
            this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
              ?.shadow,
          backgroundColor:
            this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
              ?.background_color,
          iconColor:
            this.theme?.ui_elements?.choice?.image_icon_skin?.active_style
              ?.icon_color,
        },
      }
    },
    buttonRadioChoice() {
      return {
        defaultStyle: {
          borderStroke:
            this.theme?.ui_elements?.radio_checkbox?.default_style?.border
              ?.width,
          borderStyle:
            this.theme?.ui_elements?.radio_checkbox?.default_style?.border
              ?.style,
          borderColor:
            this.theme?.ui_elements?.radio_checkbox?.default_style?.border
              ?.color,
          color: this.theme?.ui_elements?.radio_checkbox?.default_style?.color,
          margin: this.theme?.ui_elements?.radio_checkbox?.margin,
        },
        hoverStyle: {
          background: this.theme?.ui_elements?.radio_checkbox?.hover_color,
          color: this.theme?.ui_elements?.radio_checkbox?.hover_style?.color,
          stroke:
            this.theme?.ui_elements?.radio_checkbox?.hover_style?.border?.width,
          style:
            this.theme?.ui_elements?.radio_checkbox?.hover_style?.border?.style,
          borderColor:
            this.theme?.ui_elements?.radio_checkbox?.hover_style?.border?.color,
        },
        activeStyle: {
          background: this.theme?.ui_elements?.radio_checkbox?.checked_color,
          color: this.theme?.ui_elements?.radio_checkbox?.active_style?.color,
          stroke:
            this.theme?.ui_elements?.radio_checkbox?.active_style?.border
              ?.width,
          style:
            this.theme?.ui_elements?.radio_checkbox?.active_style?.border
              ?.style,
          borderColor:
            this.theme?.ui_elements?.radio_checkbox?.active_style?.border
              ?.color,
        },
      }
    },
    navigationStyle() {
      const { back_button, next_button, submit_button } =
        this.theme?.ui_elements?.step_navigation || {}

      const createButtonStyle = (buttonConfig) => {
        if (!buttonConfig) return null

        return {
          default: {
            // Text and Layout Properties
            text: buttonConfig?.text,
            icon: buttonConfig?.icon,
            iconPosition: buttonConfig?.iconPosition,
            width: buttonConfig?.width + "%",
            height: buttonConfig?.font?.height + "px",
            alignment: buttonConfig?.alignment,
            hide: buttonConfig?.hide || buttonConfig?.hidden,

            // Visual Properties
            backgroundColor: buttonConfig?.backgroundColor,
            rounded: buttonConfig?.rounded,
            shadow: buttonConfig?.shadow,

            // Font Properties
            font: {
              family: this.filterFontFamily(buttonConfig?.font?.family),
              size: buttonConfig?.font?.size + "px",
              weight: buttonConfig?.font?.weight,
              color: buttonConfig?.font?.color,
            },

            // Border Properties
            border: {
              style: buttonConfig?.default_style?.border?.style,
              color: buttonConfig?.default_style?.border?.color,
              width: buttonConfig?.default_style?.border?.width,
              radius: buttonConfig?.borderRadius,
            },
          },

          hover: {
            color: buttonConfig?.hover_style?.color,
            backgroundColor: buttonConfig?.hover_style?.backgroundColor,
            border: {
              style: buttonConfig?.hover_style?.border?.style,
              color: buttonConfig?.hover_style?.border?.color,
              width: buttonConfig?.hover_style?.border?.width,
            },
          },

          active: {
            color: buttonConfig?.active_style?.color,
            backgroundColor: buttonConfig?.active_style?.backgroundColor,
            border: {
              style: buttonConfig?.active_style?.border?.style,
              color: buttonConfig?.active_style?.border?.color,
              width: buttonConfig?.active_style?.border?.width,
            },
          },
        }
      }

      return {
        back: createButtonStyle(back_button),
        continue: createButtonStyle(next_button),
        submit: createButtonStyle(submit_button),
      }
    },
    computedRadius() {
      return (radius) => {
        return this.mapBorderRadius(radius)
      }
    },
    computedHeight: function () {
      return (height) => {
        return this.mapInputHeight(height)
      }
    },
  }
}
</script>

<style scoped>
/* Input Field Spacing */
:deep(.v-text-field) {
  margin-bottom: v-bind('inputStyles?.input?.spacing') !important;
}

/* Validation Messages Styling */
:deep(.v-input__details) {
  padding-inline: 0 !important;
  padding: 10px 0 20px 0 !important;
}

:deep(.v-messages) {
  color: v-bind('warningStyle?.color') !important;
  font-family: v-bind('warningStyle?.fontFamily') !important;
  font-size: 14px !important;
}

/* Clearable Icon Colors */
:deep(.v-field--error:not(.v-field--disabled) .v-field__clearable > .v-icon) {
  color: v-bind('warningStyle?.color') !important;
}

:deep(.v-field__clearable > .v-icon) {
  color: v-bind('theme?.general?.colors?.active_color') !important;
}

/* Input Field Styling */
:deep(.lf-form__question .v-field__input) {
  font-family: v-bind('inputStyles?.font?.family') !important;
  font-size: v-bind('inputStyles?.font?.size') !important;
  text-indent: v-bind('inputStyles?.font?.indent') !important;
  color: v-bind('inputStyles?.font?.color') !important;
  text-align: v-bind('inputStyles?.font?.align') !important;
}

:deep(.lf-form__question .v-field__overlay) {
  background-color: v-bind('inputStyles?.input?.backgroundColor') !important;
}

:deep(.lf-form__question .v-text-field input::placeholder) {
  color: v-bind('inputStyles?.input?.placeholderColor') !important;
}

/* Outlined Field States */
:deep(.lf-form__question .v-field.v-field--variant-outlined .v-field__outline) {
  --v-field-border-width: 0px;
  border-width: v-bind('inputStyles?.border?.stroke + "px"') !important;
  border-style: v-bind('inputStyles?.border?.style') !important;
  border-color: v-bind('inputStyles?.border?.color') !important;
}

:deep(.lf-form__question .v-field.v-field--variant-outlined.v-field--focused:not(.v-field--error) .v-field__outline) {
  --v-field-border-width: 0px;
  border-width: v-bind('inputStyles?.border?.stroke + "px"') !important;
  border-style: v-bind('inputStyles?.border?.style') !important;
  border-color: v-bind('theme?.general?.colors?.active_color') !important;
}

:deep(.lf-form__question .v-field.v-field--variant-outlined.v-field--error .v-field__outline) {
  border-width: v-bind('inputStyles?.border?.stroke + "px"') !important;
  border-style: v-bind('inputStyles?.border?.style') !important;
  border-color: v-bind('warningStyle?.color') !important;
}

/* Underlined Field States */
:deep(.lf-form__question .v-field.v-field--variant-underlined .v-field__outline) {
  --v-field-border-width: 0px;
  border-bottom-width: v-bind('inputStyles?.border?.stroke + "px"') !important;
  border-bottom-style: v-bind('inputStyles?.border?.style') !important;
  border-bottom-color: v-bind('inputStyles?.border?.color') !important;
}

:deep(.lf-form__question .v-field.v-field--variant-underlined.v-field--focused:not(.v-field--error) .v-field__outline) {
  border-bottom-width: v-bind('inputStyles?.border?.stroke + "px"') !important;
  border-bottom-style: v-bind('inputStyles?.border?.style') !important;
  border-bottom-color: v-bind('theme?.general?.colors?.active_color') !important;
}

:deep(.lf-form__question .v-field.v-field--variant-underlined.v-field--error .v-field__outline) {
  border-bottom-width: v-bind('inputStyles?.border?.stroke + "px"') !important;
  border-bottom-style: v-bind('inputStyles?.border?.style') !important;
  border-bottom-color: v-bind('warningStyle?.color') !important;
}

:deep(.lf-form__question .v-field--variant-underlined .v-field__outline::before) {
  border: none;
}

:deep(.lf-form__question .v-field--variant-underlined .v-field__outline::after) {
  border: none;
}

/* For Scale Input */
:deep(.lf-form__question__scale .v-field__overlay) {
  background-color: v-bind('inputStyles?.input?.backgroundColor') !important;
}

:deep(.lf-form__question__scale .v-field__input),
:deep(.lf-form__question__scale .v-field--active .v-text-field__suffix),
:deep(.lf-form__question__scale .v-field--active .v-text-field__prefix) {
  font-family: v-bind('inputStyles?.font?.family') !important;
  color: v-bind('inputStyles?.font?.color') !important;
}

:deep(.lf-form__question__scale .v-field--active .v-label.v-field-label) {
  opacity: 1 !important;
}

/* STEP NAVIGATION */
:deep(.continue-btn) {
  width: v-bind('navigationStyle?.continue?.default?.width') !important;
  background-color: v-bind('navigationStyle?.continue?.default?.backgroundColor') !important;

  border-width: v-bind('navigationStyle?.continue?.default?.border?.width + "px"') !important;
  border-style: v-bind('navigationStyle?.continue?.default?.border?.style') !important;
  border-color: v-bind('navigationStyle?.continue?.default?.border?.color') !important;

  &:hover {
    background-color: v-bind('navigationStyle?.continue?.hover?.backgroundColor') !important;

    border-width: v-bind('navigationStyle?.continue?.hover?.border?.width + "px"') !important;
    border-style: v-bind('navigationStyle?.continue?.hover?.border?.style') !important;
    border-color: v-bind('navigationStyle?.continue?.hover?.border?.color') !important;

    & span,
    .continue-btn-icon {
      color: v-bind('navigationStyle?.continue?.hover?.color') !important;
    }
  }

  &:active {
    background-color: v-bind('navigationStyle?.continue?.active?.backgroundColor') !important;

    border-width: v-bind('navigationStyle?.continue?.active?.border?.width + "px"') !important;
    border-style: v-bind('navigationStyle?.continue?.active?.border?.style') !important;
    border-color: v-bind('navigationStyle?.continue?.active?.border?.color') !important;

    & span,
    .continue-btn-icon {
      color: v-bind('navigationStyle?.continue?.active?.color') !important;
    }
  }
}

.continue-btn span {
  text-transform: none !important;
  color: v-bind('navigationStyle?.continue?.default?.font?.color') !important;
  font-family: v-bind('navigationStyle?.continue?.default?.font?.family') !important;
  font-size: v-bind('navigationStyle?.continue?.default?.font?.size') !important;
  font-weight: v-bind('navigationStyle?.continue?.default?.font?.weight') !important;
}

.continue-btn-icon {
  color: v-bind('navigationStyle?.continue?.default?.font?.color') !important;
  font-size: v-bind('navigationStyle?.continue?.default?.font?.size') !important;
  font-weight: v-bind('navigationStyle?.continue?.default?.font?.weight') !important;
}

:deep(.submit-btn) {
  width: v-bind('navigationStyle?.submit?.default?.width') !important;
  background-color: v-bind('navigationStyle?.submit?.default?.backgroundColor') !important;

  border-width: v-bind('navigationStyle?.submit?.default?.border?.width + "px"') !important;
  border-style: v-bind('navigationStyle?.submit?.default?.border?.style') !important;
  border-color: v-bind('navigationStyle?.submit?.default?.border?.color') !important;

  &:hover {
    background-color: v-bind('navigationStyle?.submit?.hover?.backgroundColor') !important;

    border-width: v-bind('navigationStyle?.submit?.hover?.border?.width + "px"') !important;
    border-style: v-bind('navigationStyle?.submit?.hover?.border?.style') !important;
    border-color: v-bind('navigationStyle?.submit?.hover?.border?.color') !important;

    & span,
    .submit-btn-icon {
      color: v-bind('navigationStyle?.submit?.hover?.color') !important;
    }
  }

  &:active {
    background-color: v-bind('navigationStyle?.submit?.active?.backgroundColor') !important;

    border-width: v-bind('navigationStyle?.submit?.active?.border?.width + "px"') !important;
    border-style: v-bind('navigationStyle?.submit?.active?.border?.style') !important;
    border-color: v-bind('navigationStyle?.submit?.active?.border?.color') !important;

    & span,
    .submit-btn-icon {
      color: v-bind('navigationStyle?.submit?.active?.color') !important;
    }
  }
}

.submit-btn span {
  text-transform: none !important;
  color: v-bind('navigationStyle?.submit?.default?.font?.color') !important;
  font-family: v-bind('navigationStyle?.submit?.default?.font?.family') !important;
  font-size: v-bind('navigationStyle?.submit?.default?.font?.size') !important;
  font-weight: v-bind('navigationStyle?.submit?.default?.font?.weight') !important;
}

.submit-btn-icon {
  color: v-bind('navigationStyle?.submit?.default?.font?.color') !important;
  font-size: v-bind('navigationStyle?.submit?.default?.font?.size') !important;
  font-weight: v-bind('navigationStyle?.submit?.default?.font?.weight') !important;
}

:deep(.back-btn) {
  width: v-bind('navigationStyle?.back?.default?.width') !important;
  background-color: v-bind('navigationStyle?.back?.default?.backgroundColor') !important;

  border-width: v-bind('navigationStyle?.back?.default?.border?.width + "px"') !important;
  border-style: v-bind('navigationStyle?.back?.default?.border?.style') !important;
  border-color: v-bind('navigationStyle?.back?.default?.border?.color') !important;

  &:hover {
    background-color: v-bind('navigationStyle?.back?.hover?.backgroundColor') !important;
    border-width: v-bind('navigationStyle?.back?.hover?.border?.width + "px"') !important;
    border-style: v-bind('navigationStyle?.back?.hover?.border?.style') !important;
    border-color: v-bind('navigationStyle?.back?.hover?.border?.color') !important;

    & span,
    .back-btn-icon {
      color: v-bind('navigationStyle?.back?.hover?.color') !important;
    }
  }

  &:active {
    background-color: v-bind('navigationStyle?.back?.active?.backgroundColor') !important;

    border-width: v-bind('navigationStyle?.back?.active?.border?.width + "px"') !important;
    border-style: v-bind('navigationStyle?.back?.active?.border?.style') !important;
    border-color: v-bind('navigationStyle?.back?.active?.border?.color') !important;

    & span,
    .back-btn-icon {
      color: v-bind('navigationStyle?.back?.active?.color') !important;
    }
  }
}

.back-btn span {
  text-transform: none !important;
  color: v-bind('navigationStyle?.back?.default?.font?.color') !important;
  font-family: v-bind('navigationStyle?.back?.default?.font?.family') !important;
  font-size: v-bind('navigationStyle?.back?.default?.font?.size') !important;
  font-weight: v-bind('navigationStyle?.back?.default?.font?.weight') !important;
}

.back-btn-icon {
  color: v-bind('navigationStyle?.back?.default?.font?.color') !important;
  font-size: v-bind('navigationStyle?.back?.default?.font?.size') !important;
  font-weight: v-bind('navigationStyle?.back?.default?.font?.weight') !important;
}

/* FORM */
.formPadding {
  padding: v-bind('theme?.ui_elements?.background?.form_padding_top + "px " + theme?.ui_elements?.background?.form_padding_right + "px " + theme?.ui_elements?.background?.form_padding_bottom + "px " + theme?.ui_elements?.background?.form_padding_left + "px"') !important;
}

.lf-form__wrapper {
  background-color: v-bind('theme?.ui_elements?.background?.formColor') !important;
  border-width: v-bind('theme?.ui_elements?.background?.form_border_width + "px"') !important;
  border-style: v-bind('theme?.ui_elements?.background?.form_border_style') !important;
  border-color: v-bind('theme?.ui_elements?.background?.form_border_color') !important;
}

.lf-shadow {
  box-shadow: 11px 17px 50px 0px #727eb721;
}

/* CHOICE BUTTONS */
:deep(.choice-btn) {
  border-width: v-bind('buttonRadioChoice.defaultStyle.borderStroke + "px"') !important;
  border-style: v-bind('buttonRadioChoice.defaultStyle.borderStyle') !important;
  border-color: v-bind('buttonRadioChoice.defaultStyle.borderColor') !important;
  margin-bottom: v-bind('buttonRadioChoice.defaultStyle.margin + "px"') !important;
  color: v-bind('buttonRadioChoice.defaultStyle.color') !important;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &:hover {
    background-color: v-bind('buttonRadioChoice.hoverStyle.background') !important;
    color: v-bind('buttonRadioChoice.hoverStyle.color') !important;
    border-width: v-bind('buttonRadioChoice.hoverStyle.stroke + "px"') !important;
    border-style: v-bind('buttonRadioChoice.hoverStyle.style') !important;
    border-color: v-bind('buttonRadioChoice.hoverStyle.borderColor') !important;
  }

  &.choice-btn.selected {
    background-color: v-bind('buttonRadioChoice.activeStyle.background') !important;
    color: v-bind('buttonRadioChoice.activeStyle.color') !important;
    border-width: v-bind('buttonRadioChoice.activeStyle.stroke + "px"') !important;
    border-style: v-bind('buttonRadioChoice.activeStyle.style') !important;
    border-color: v-bind('buttonRadioChoice.activeStyle.borderColor') !important;
  }
}

/* CHOICE IMAGE/ICON */
.choice-selected-icon {
  z-index: 1;
  border-radius: 50%;
  background-color: white;
}

:root {
  --icon-size: 45px;
  --icon-color: blue;
}

.choice-icon {
  color: v-bind('imageIconStyles?.defaultStyle?.iconColor') !important;
  font-size: var(--icon-size);
}

:deep(.icon-card) {
  border-width: v-bind('imageIconStyles?.defaultStyle?.border?.width + "px"') !important;
  border-style: v-bind('imageIconStyles?.defaultStyle?.border?.style') !important;
  border-color: v-bind('imageIconStyles?.defaultStyle?.border?.color') !important;
  box-shadow: v-bind('imageIconStyles?.defaultStyle?.shadow ? "11px 17px 50px 0px #727eb721" : "none"') !important;

  &:hover {
    border-width: v-bind('imageIconStyles?.hoverStyle?.border?.width + "px"') !important;
    border-style: v-bind('imageIconStyles?.hoverStyle?.border?.style') !important;
    border-color: v-bind('imageIconStyles?.hoverStyle?.border?.color') !important;
    background-color: v-bind('imageIconStyles?.hoverStyle?.backgroundColor') !important;
    box-shadow: v-bind('imageIconStyles?.hoverStyle?.shadow ? "11px 17px 50px 0px #727eb721" : "none"') !important;

    .choice-label {
      color: v-bind('imageIconStyles?.hoverStyle?.title?.color') !important;
    }

    .choice-description {
      color: v-bind('imageIconStyles?.hoverStyle?.description?.color') !important;
    }

    .choice-icon {
      color: v-bind('imageIconStyles?.hoverStyle?.iconColor') !important;
    }
  }

  &.selected {
    border-width: v-bind('imageIconStyles?.activeStyle?.border?.width + "px"') !important;
    border-style: v-bind('imageIconStyles?.activeStyle?.border?.style') !important;
    border-color: v-bind('imageIconStyles?.activeStyle?.border?.color') !important;
    background-color: v-bind('imageIconStyles?.activeStyle?.backgroundColor') !important;
    box-shadow: v-bind('imageIconStyles?.activeStyle?.shadow ? "11px 17px 50px 0px #727eb721" : "none"') !important;

    .choice-label {
      color: v-bind('imageIconStyles?.activeStyle?.title?.color') !important;
    }

    .choice-description {
      color: v-bind('imageIconStyles?.activeStyle?.description?.color') !important;
    }

    .choice-icon {
      color: v-bind('imageIconStyles?.activeStyle?.iconColor') !important;
    }
  }

  .card-item {
    padding-top: v-bind('imageIconStyles?.defaultStyle?.padding?.top + "px"') !important;
    padding-right: v-bind('imageIconStyles?.defaultStyle?.padding?.right + "px"') !important;
    padding-bottom: v-bind('imageIconStyles?.defaultStyle?.padding?.bottom + "px"') !important;
    padding-left: v-bind('imageIconStyles?.defaultStyle?.padding?.left + "px"') !important;
  }

  .choice-label {
    font-family: v-bind('imageIconStyles?.defaultStyle?.title?.fontFamily') !important;
    font-size: v-bind('imageIconStyles?.defaultStyle?.title?.fontSize + "px"') !important;
    font-weight: v-bind('imageIconStyles?.defaultStyle?.title?.fontWeight') !important;
    color: v-bind('imageIconStyles?.defaultStyle?.title?.color') !important;
    line-height: v-bind('imageIconStyles?.defaultStyle?.title?.lineHeight + "px"') !important;
    word-wrap: break-word;
    white-space: normal;
  }

  .choice-description {
    font-family: v-bind('imageIconStyles?.defaultStyle?.description?.fontFamily') !important;
    font-size: v-bind('imageIconStyles?.defaultStyle?.description?.fontSize + "px"') !important;
    font-weight: v-bind('imageIconStyles?.defaultStyle?.description?.fontWeight') !important;
    color: v-bind('imageIconStyles?.defaultStyle?.description?.color') !important;
    line-height: v-bind('imageIconStyles?.defaultStyle?.description?.lineHeight + "px"') !important;
    word-wrap: break-word;
    white-space: normal;
  }
}

/* Needed for underlined variant */
:deep(.v-input--density-comfortable .v-field--variant-plain),
:deep(.v-input--density-comfortable .v-field--variant-underlined) {
  --v-field-padding-bottom: 12px !important;
  /* --v-field-padding-start: 12px !important; */
}

:deep(.v-input--density-compact .v-field--variant-plain),
:deep(.v-input--density-compact .v-field--variant-underlined) {
  --v-field-padding-bottom: 8px !important;
  /* --v-field-padding-start: 8px !important; */
}

:deep(.v-input--density-default .v-field--variant-plain),
:deep(.v-input--density-default .v-field--variant-underlined) {
  --v-field-padding-bottom: 16px !important;
  /* --v-field-padding-start: 12px !important; */
}
</style>

<style>
.custom-progress-bar {
  transition: width 0.6s ease;
}

/* Disable animation ONLY if no-animation class is present */
.no-animation.v-progress-linear--striped .v-progress-linear__determinate {
  animation: none !important;
}

/* Set hover state for unselected radio buttons */
.checkbox-radio .v-selection-control__input:hover .mdi-radiobox-blank::before {
  content: "\F0131";
}

/* Define unselected radio button state */
.checkbox-radio .v-selection-control__input .mdi-radiobox-blank::before {
  content: "\F0131";
}

/* Define selected radio button state */
.checkbox-radio .v-selection-control__input .mdi-radiobox-marked::before {
  content: "\F0132";
}

.checkbox-radio, .radio-choices {
  margin-bottom: v-bind('theme?.ui_elements?.radio_checkbox?.margin + "px"') !important;
}

/* SMILEY */
.smiley-range {
  color: v-bind('scaleStyle?.likert?.smileyColor');
  transition: all 0.3s ease;
}

.smiley-text {
  color: v-bind('scaleStyle?.likert?.smileyText');
}

.smiley-range:hover {
  color: v-bind('scaleStyle?.likert?.smileyHoverColor');
  transform: scale(1.1);
}

/* Conditional active state */
.smiley-range.active {
  color: v-bind('scaleStyle?.likert?.smileyActiveColor');
  transform: scale(1.1);
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.smiley-text.active {
  color: v-bind('scaleStyle?.likert?.smileyTextActive');
}
</style>
