<template>
  <div class="conditon-builder-cpt">
    <div class="text-end mb-5 mt-n12" style="width: 90%;">
      <v-btn :disabled="saveData.saving" class="text-none font-weight-bold"
        :prepend-icon="isEditView ? 'mdi-update' : 'mdi-plus'" :loading="saveData.saving" type="submit"
        color="primary-red" @click="saveConditionBuilderData">{{ isEditView ?
          'Update' : 'Add' }}</v-btn>
    </div>
    <v-form ref="formConditionBuilder">
      <v-row v-if="flags.showLoader" class="d-flex justify-center wrapper  mt-7  mb-7 pa-6">
        <div class="text-center" v-if="!flags.errorOccured">
          <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
            color="secondary-red"></v-progress-circular>
        </div>
        <div class="text-center" v-else>
          <h6>Whoops, looks like something went wrong. Please click the button to try again.</h6>
          <v-btn class="text-none font-weight-bold" color="primary-red" @click="reloadPage()"
            prepend-icon="mdi-refresh">Reload</v-btn>
        </div>
      </v-row>
      <!-- CONDITIOIN -->
      <v-card v-else rounded="md" elevation="0" width="80%"
        class="condition-wrapper border-thin border-t-thin rounded-t-sm">
        <v-card-title class="bg-blue-lighten-5 text-primary-blue">
          <h6 class="d-flex justify-center mb-0 align-end">
            <v-icon icon="mdi-vector-link mb-1"></v-icon>
            <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
              CONDITION
            </p>
          </h6>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-row class="mb-n7 justify-center">
            <v-col cols="12" md="10">
              <v-text-field v-model="conditionBuilder.endingTitle"
                placeholder="Enter the name of the ending (e.g., 'Form Ending 1')" prepend-inner-icon="mdi-text-short"
                density="comfortable" label="Set Ending Title" variant="outlined" persistent-placeholder
                :rules="$valid([{ rule: 'required', fieldName: 'The title field' }, { rule: 'between', min: '1', max: '50' }])"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-n7 justify-center">
            <v-col cols="12" md="10">
              <v-autocomplete :rules="$valid([{ rule: 'required', fieldName: 'The condition trigger field' }])"
                :placeholder="endingType === formEndings.VISITORBASED ? 'Select Field' : 'Select Question'"
                persistent-placeholder suffix="IF" @update:model-value="resetFields" :label="placeholderTrigger"
                v-model="conditionBuilder.triggerSelected" :items="filteredArray"
                prepend-inner-icon="mdi-text-box-multiple" density="comfortable" variant="outlined"
                @input="getQuestionChoices" return-object></v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mb-n7 justify-center"
            v-if="endingType === formEndings.VISITORBASED && conditionBuilder.triggerSelected?.type === 'HIDDENFIELD'">
            <v-col cols="12" md="10">
              <v-autocomplete :rules="$valid([{ rule: 'required', fieldName: 'The hidden field' }])" variant="outlined"
                suffix="HIDDEN FIELD" persistent-placeholder return-object density="comfortable" item-title="name"
                label="Select Hidden field" :items="hiddenFields" v-model="conditionBuilder.triggerSelected.name">
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mb-n7 justify-center">
            <v-col cols="12" md="10">
              <v-autocomplete :rules="$valid([{ rule: 'required', fieldName: 'The condition' }])" suffix="CONDITION"
                :disabled="!conditionBuilder.triggerSelected" v-model="conditionBuilder.conditionSelected"
                placeholder="Choose Condition" persistent-placeholder :items="conditionOptions"
                prepend-inner-icon="mdi-vector-link" density="comfortable" variant="outlined"
                :label="placeholderState"></v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mb-n7 justify-center" v-if="!isDisabled">
            <v-col cols="12" md="10">
              <v-autocomplete :rules="$valid([{ rule: 'required', fieldName: 'The condition value' }])" suffix="VALUE"
                prepend-inner-icon="mdi-database" density="comfortable" variant="outlined" v-if="valueInputType"
                label="Select Value" placeholder="Select Value" v-model="conditionBuilder.valueSelected"
                :items="valueInputOptions" :disabled="!conditionBuilder.conditionSelected">
              </v-autocomplete>
              <v-text-field v-else :rules="$valid([{ rule: 'required', fieldName: 'The condition value' }])"
                :placeholder="placeholderText" v-model="conditionBuilder.valueSelected" persistent-placeholder
                :disabled="!conditionBuilder.conditionSelected" prepend-inner-icon="mdi-database" suffix="VALUE"
                density="comfortable" variant="outlined" label="Type the value that should meet the condition"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Action -->
      <v-card rounded="md" elevation="0" width="80%"
        class="condition-wrapper border-thin border-t-thin rounded-t-sm my-8">
        <v-card-title class="bg-blue-lighten-5 text-primary-blue">
          <h6 class="d-flex justify-center align-end mb-0">
            <v-icon icon="mdi-gesture-tap-button mb-1"></v-icon>
            <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
              ACTION
            </p>
          </h6>
        </v-card-title>
        <v-card-text class="pt-8">
          <v-row class="mb-n7 justify-center">
            <v-col cols="12" md="10">
              <v-select :rules="$valid([{ rule: 'required', fieldName: 'The submission type field' }])"
                placeholder="Select Action" persistent-placeholder prepend-inner-icon="mdi-cogs" density="comfortable"
                suffix="DO" label="Select 'Thank you' Page action" item-title="label" return-object
                :items="formSubmitActionArr" v-model="conditionBuilder.submitAction" variant="outlined">
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mb-n7 justify-center"
            v-if="conditionBuilder.submitAction && conditionBuilder.submitAction.value === formSubmitActionArr[0].value">
            <v-col cols="12" md="10">
              <v-text-field
                :rules="$valid([{ rule: 'required', fieldName: 'The URL field' }, { rule: 'url', fieldName: 'The URL' }])"
                prepend-inner-icon="mdi-link-box" density="comfortable" label="Set Thank you page URL"
                persistent-placeholder placeholder="Set URL" v-model="conditionBuilder.thankyouUrl" variant="outlined">
                <template v-slot:append-inner>
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props" icon="mdi-information"></v-icon>
                    </template>
                    Please enter the full URL, including http:// or https://
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="d-flex ga-10 mt-n4" cols="12" md="10">
              <v-switch inset color="secondary-red" label="Post form data to URL"
                v-model="conditionBuilder.postDataToUrl"></v-switch>
              <v-switch inset color="secondary-red" label="Append data to URL"
                v-model="conditionBuilder.appendDataToUrl"></v-switch>
            </v-col>
          </v-row>
          <v-row class="mb-n7 justify-center"
            v-if="conditionBuilder.submitAction && conditionBuilder.submitAction.value === formSubmitActionArr[1].value">
            <v-col cols="12" md="10" style="height: fit-content;">
              <quill-editor v-model:content="conditionBuilder.thankyouMessage" :options="editorOption"
                placeholder="Set Thankyou Message" name="thankyou-message" contentType="html">
              </quill-editor>
              <v-switch inset color="secondary-red" hide-details class="my-4"
                label="Trim trailing zeros from calculator result"
                v-model="conditionBuilder.trimTrailingZeros"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar rounded="md" height="50" v-model="snackbar.visible" :timeout="5000"
      :color="snackbar.error ? 'secondary-red' : 'primary-green'">
      <p class=" text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.message }} </p>
    </v-snackbar>
  </div>
</template>

<script>
import { editorMixin } from '@/mixins/form-endings'
import userMixin from '@/mixins/user'
import _ from 'lodash'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import packageBuilderMixin from '@/mixins/package-builder'

export default {
  mixins: [editorMixin, userMixin, packageBuilderMixin],
  components: {
    'quill-editor': QuillEditor
  },
  props: {
    endingType: {
      type: String
    },
    isEditView: {
      type: Boolean
    },
    endingTrigger: {
      type: Array
    },
    placeholderTrigger: {
      type: String
    },
    placeholderState: {
      type: String
    },
    hiddenFields: {
      type: Array
    }
  },
  data: function () {
    return {
      snackbar: {
        visible: false,
        message: '',
        error: false
      },
      conditionBuilder: {
        type: this.endingType,
        prioritySelected: null,
        endingTitle: null,
        triggerSelected: null,
        conditionSelected: '',
        valueSelected: '',
        submitAction: null,
        postDataToUrl: false,
        appendDataToUrl: false,
        enableThankyouUrl: false,
        thankyouMessage: null,
        thankyouUrl: null,
        formId: this.$route.params.id,
        variantId: this.$route.params.variantId,
        conditionsData: null,
        questionId: null,
        trimTrailingZeros: false
      },
      flags: {
        showLoader: true,
        errorOccured: false
      },
      saveData: {
        saving: false,
        savingSuccess: false
      }
    }
  },
  mounted: function () {
    this.endingsMixin_getFormQuestions()
    // this.fetchFormState()
    if (this.isEditView) {
      setTimeout(() => {
        this.loadAnswerBasedEndingData()
        this.flags.showLoader = false
      }, 1000)
    }
  },
  methods: {
    loadAnswerBasedEndingData: function () {
      this.$store.dispatch('formendings/getFormEndingData', { id: this.$route.params.endingId, type: this.conditionBuilder.type, userId: this.user.id })
        .then(({ data: { data: { formEndings, conditions } } }) => {
          const firstCondition = conditions[0];

          const isAnswerBased = this.endingType === this.formEndings.ANSWERBASED;
          const isVisitorBased = this.endingType === this.formEndings.VISITORBASED;

          if (isAnswerBased) {
            this.conditionBuilder.questionId = firstCondition.question_id
            this.conditionBuilder.triggerSelected = this.checkIfQuestionIsDeleted ? null : this.checkIfQuestionTitleUpdated
            this.conditionBuilder.valueSelected = this.checkIfQuestionIsDeleted ? null : this.checkIfValueChanged(firstCondition.value)
          } else if (isVisitorBased) {
            const parsedData = this.parseJsonValue(firstCondition.field)
            this.conditionBuilder.triggerSelected = parsedData
            this.conditionBuilder.valueSelected = firstCondition.value
          }

          Object.assign(this.conditionBuilder, {
            endingTitle: formEndings.title,
            conditionSelected: firstCondition.operator,
            submitAction: _.find(this.formSubmitActionArr, { value: formEndings.submit_action }),
            thankyouMessage: formEndings.thankyou_message,
            thankyouUrl: formEndings.thankyou_url,
            trimTrailingZeros: formEndings.trim_trailing_zeros === 1,
            formId: formEndings.form_id,
            appendDataToUrl: formEndings.append_data_to_url === 1,
            postDataToUrl: formEndings.post_data_to_url === 1,
            conditionsData: conditions,
          });
        })
        .catch((error) => {
          this.flags.errorOccured = true
          throw error
        })
    },

    resetFields: function () {
      this.conditionBuilder.conditionSelected = ''
      this.conditionBuilder.valueSelected = ''
    },
    reloadPage: function () {
      window.location.reload()
    },
    saveConditionBuilderData: async function () {
      this.setSavingState(true)

      const { valid } = await this.$refs.formConditionBuilder.validate()
      if (!valid) {
        this.setSavingState(false)
        return
      }

      if (!this.canCreateOrUpdateConditions()) {
        this.setSavingState(false)
        return
      }

      this.saveEndingData()
    },

    setSavingState: function (isSaving) {
      this.saveData.saving = isSaving
      this.saveData.savingSuccess = isSaving
    },

    canCreateOrUpdateConditions: function () {
      if (!this.packageBuilderMixin_canUseMultipleEndings()) {
        this.handleUpgradeError('Please upgrade to an appropriate Plan in order to create or update conditions.')
        return false
      }

      if (this.isRestrictedEndingType()) {
        const endingTypeName = this.endingType === this.formEndings.ANSWERBASED ? 'Answer Based' : 'Visitor Based'
        const errorMessage = `Upgrade your plan to access ${endingTypeName} Endings for creating or updating conditions.`
        this.handleUpgradeError(errorMessage)
        return false
      }

      return true
    },

    isRestrictedEndingType: function () {
      return (this.endingType === this.formEndings.ANSWERBASED && !this.packageBuilderMixin_canUseEndingType(this.formEndings.ANSWERBASED)) ||
        (this.endingType === this.formEndings.VISITORBASED && !this.packageBuilderMixin_canUseEndingType(this.formEndings.VISITORBASED))
    },

    saveEndingData: function () {
      let endingAction = this.isEditView ? 'updateAnswerBasedData' : 'saveAnswerBasedData'
      let dispatchData = this.getDispatchData()

      this.$store.dispatch(`formendings/${endingAction}`, dispatchData)
        .then(() => {
          this.handleSaveSuccess()
        }, () => {
          this.handleSaveFailure()
        })
    },

    getDispatchData: function () {
      return this.isEditView
        ? { id: parseInt(this.$route.params.endingId), data: this.conditionBuilder, userId: this.user.id }
        : { userId: this.user.id, data: this.conditionBuilder }
    },

    handleSaveSuccess: function () {
      this.setSavingState(true)
      this.showSnackbar(false, `${this.isEditView ? 'Ending updated' : 'Ending saved'} successfully. Redirecting...`)
      setTimeout(() => {
        this.redirectAfterSave()
      }, 2000)
    },

    handleSaveFailure: function () {
      this.setSavingState(false)
      this.showSnackbar(true, 'Something went wrong.')
    },

    redirectAfterSave: function () {
      this.$router.push({ name: `forms.variants.edit`, params: { id: this.$route.params.id, variantId: this.$route.params.variantId, tab: 'formendings' } })
    },
    handleUpgradeError: function (message) {
      this.saveData.saving = false
      this.showSnackbar(true, message)
    },
    showSnackbar: function (flag, message) {
      this.snackbar.visible = true;
      this.snackbar.error = flag
      this.snackbar.message = message;
    },
    checkIfValueChanged: function (oldValue) {
      const item = this.getQuestion
      if (item && [this.questionTypesMap.SINGLE_CHOICE, this.questionTypesMap.MULTIPLE_CHOICE].includes(item.type)) {
        if (!item.choices.some(choice => choice.label === oldValue)) {
          this.conditionBuilder.valueSelected = ''
          return this.conditionBuilder.valueSelected
        }
      }
      return oldValue
    },
    parseJsonValue: function (value) {
      try {
        const parsedData = JSON.parse(value)
        return parsedData
      } catch (error) {
        return null
      }
    },
    findQuestionById: function (questionId) {
      return this.formQuestions.find(item => item.id === questionId)
    }
  },
  computed: {
    checkIfQuestionIsDeleted: function () {
      return !this.formQuestions.some(item => item.id === this.conditionBuilder.questionId)
    },
    checkIfQuestionTitleUpdated: function () {
      const item = this.findQuestionById(this.conditionBuilder.questionId)
      return item && this.conditionBuilder.triggerSelected !== item.title ? item.title : this.conditionBuilder.triggerSelected
    },
    filteredArray: function () {
      return this.endingType === this.formEndings.VISITORBASED && this.hiddenFields.length === 0
        ? this.endingTrigger.filter(item => item.type !== 'HIDDENFIELD')
        : this.endingTrigger
    },
    conditionOptions: function () {
      if (!this.conditionBuilder.triggerSelected) return []

      const selectedType = this.getSelectedType
      return selectedType ? selectedType.state : []
    },
    getSelectedType: function () {
      if (this.endingType === this.formEndings.ANSWERBASED) {
        return this.isEditView
          ? this.questionTypes.find(type => type.type === this.getQuestion.type)
          : this.questionTypes.find(type => type.type === this.conditionBuilder.triggerSelected.type)
      }
      return this.endingType === this.formEndings.VISITORBASED
        ? this.endingTrigger.find(item => item.type === this.conditionBuilder.triggerSelected.type)
        : null
    },
    isDisabled: function () {
      if (this.conditionBuilder.conditionSelected === this.endingConditions.ISEMPTY ||
        this.conditionBuilder.conditionSelected === this.endingConditions.ISFILLED ||
        this.conditionBuilder.conditionSelected === this.endingConditions.ISCHECKED ||
        this.conditionBuilder.conditionSelected === this.endingConditions.ISNOTCHECKED) {
        return true
      }
      return false
    },
    getQuestion: function () {
      return this.findQuestionById(this.conditionBuilder.triggerSelected.id || this.conditionBuilder.questionId)
    },
    valueInputType: function () {
      return this.isChoiceType || this.isAddressCountryCondition || this.isVisitorCountryCondition || this.isDeviceTypeCondition
    },
    isChoiceType: function () {
      const isSingleOrMultipleChoice = this.conditionBuilder.triggerSelected &&
        this.endingType === this.formEndings.ANSWERBASED &&
        (this.conditionBuilder.triggerSelected.type === this.questionTypesMap.SINGLE_CHOICE ||
          this.getQuestion.type === this.questionTypesMap.SINGLE_CHOICE ||
          this.conditionBuilder.triggerSelected.type === this.questionTypesMap.MULTIPLE_CHOICE ||
          this.getQuestion.type === this.questionTypesMap.MULTIPLE_CHOICE)
      return isSingleOrMultipleChoice
    },
    isAddressCountryCondition: function () {
      if (this.endingType === this.formEndings.ANSWERBASED) {
        const isAddressType = this.conditionBuilder.triggerSelected && (this.conditionBuilder.triggerSelected.type === this.questionTypesMap.ADDRESS || this.getQuestion.type === this.questionTypesMap.ADDRESS)
        const isCountryCondition = this.conditionBuilder.conditionSelected === this.endingConditions.COUNTRYEQUALS || this.conditionBuilder.conditionSelected === this.endingConditions.COUNTRYDOSENTEQUALS
        return isAddressType && isCountryCondition
      }
    },
    isVisitorCountryCondition: function () {
      if (this.endingType === this.formEndings.VISITORBASED) {
        return this.conditionBuilder.triggerSelected?.type === 'COUNTRY' && [this.endingConditions.COUNTRYEQUALS, this.endingConditions.COUNTRYDOSENTEQUALS].includes(this.conditionBuilder.conditionSelected)
      }
    },
    isDeviceTypeCondition: function () {
      if (this.endingType === this.formEndings.VISITORBASED) {
        return this.conditionBuilder.triggerSelected?.type === 'DEVICETYPE'
      }
    },
    valueInputOptions: function () {
      if (!this.valueInputType) return []
      return this.endingType === this.formEndings.ANSWERBASED ? this.getAnswerBasedOptions : this.getVisitorBasedOptions
    },
    getAnswerBasedOptions: function () {
      const triggerType = this.conditionBuilder.triggerSelected.type || this.getQuestion.type
      switch (triggerType) {
        case this.questionTypesMap.SINGLE_CHOICE:
          return this.singleChoices || this.getQuestion.choices.map(item => item.label)

        case this.questionTypesMap.MULTIPLE_CHOICE:
          return this.multipleChoices || this.getQuestion.choices.map(item => item.label)

        case this.questionTypesMap.ADDRESS:
          if (this.conditionBuilder.conditionSelected === this.endingConditions.COUNTRYEQUALS ||
            this.conditionBuilder.conditionSelected === this.endingConditions.COUNTRYDOSENTEQUALS) {
            return this.getCountries
          }
          break
      }
      return []
    },
    getVisitorBasedOptions: function () {
      if (this.conditionBuilder.triggerSelected.type === 'COUNTRY') {
        return this.getCountries
      } else if (this.conditionBuilder.triggerSelected.type === 'DEVICETYPE') {
        return this.deviceTypes
      }
      return []
    },
    placeholderText: function () {
      if (!this.valueInputType && this.conditionBuilder.triggerSelected && this.conditionBuilder.triggerSelected.type === this.questionTypesMap.DATE) {
        return 'Please Enter Date in YYYY-MM-DD format'
      } else {
        return 'Set Value'
      }
    }
  }
}
</script>
