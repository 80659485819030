<template>
  <div class="form-step-textbox-element">
    <div class="form-step-textbox-element__body">
      <div class="question__phishing-warning" v-if="hasPhishingContent">
        <p><strong>Warning:</strong> "Please note that for security reasons LeadGen Forms should not be used to capture
          password details of users. We do not tolerate password phishing and will close down accounts that engage in
          this behavior."
        </p>
      </div>
      <quill-editor v-model:content="element.content" contentType="html" :options="editorOption"
        @update:modelValue="updateContent($event)" label="Description">
      </quill-editor>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import * as actionTypes from '@/store/action-types'
import Quill from 'quill'
import ImageResize from 'quill-image-resize-module'
import ImageCompress from 'quill-image-compress'
import phishingWords from '@/shared/constants/form/formPhishingWords'
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageCompress', ImageCompress)
let toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'size': ['small', 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'align': [] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['link', 'image']
]
export default {
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  components: {
    'quill-editor': QuillEditor
  },
  data: function () {
    return {
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
          imageResize: true,
          imageCompress: {
            quality: 0.8,
            maxWidth: 700,
            maxHeight: 700,
            imageType: 'image/jpeg',
            ignoreImageTypes: ['image/png', 'image/gif'],
            debug: true,
            suppressErrorLogging: false,
            insertIntoEditor: undefined
          }
        }
      }
    }
  },
  methods: {
    updateContent: function (content) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_TB_ELEMENT_CONTENT, {
        elementId: this.element.id,
        stepId: this.stepId,
        content: content
      })
    }
  },
  computed: {
    getContent: function () {
      return this.element.content
    },
    hasPhishingContent: function () {
      const delimeters = [/ /, /\(/, /\)/, /\*/, /"/, /'/, /\[/, /\]/, '-', '_']

      if (!this.getContent) {
        return false
      }

      for (let word of phishingWords) {
        if (this.getContent?.toLowerCase().indexOf(word?.toLowerCase()) >= 0) {
          return true
        }

        for (let delimeter of delimeters) {
          let replacedContent = this.getContent.replace(new RegExp(delimeter, 'g'), '')

          if (replacedContent?.toLowerCase().indexOf(word?.toLowerCase()) >= 0) {
            return true
          }
        }

        return false
      }
    }
  }
}
</script>
