<template>
  <div class="form-step-component">
    <div class="form-step">
      <!-- STEP HEADER -->
      <v-row class="step-head-new">
        <v-col cols="2"></v-col>
        <v-col class="text-center" cols="8">
          <div>
            <h5 class="step-head__title"><span>{{ 'STEP ' + (getStepIndex(id) + 1) }} of {{ steps.length }}</span> </h5>
          </div>
        </v-col>
        <v-col class="text-end" cols="2"><v-icon v-if="steps.length > 1" @click="openDialog('deleteStepModal')"
            title="Delete Step" :size="25" color="secondary-red">mdi-delete</v-icon></v-col>
      </v-row>
      <!-- STEP BODY -->
      <div class="form-step__items">
        <div class="form-step__item list-group-item" v-for="(stepItem, stepItemIndex) in stepItems"
          :key="stepItemIndex">
          <!-- ELEMENT -->
          <div class="form-step__item-element" v-if="stepItem.type === 'element'">
            <div class="form-step__item__action-top">
              <div class="form-step__item__action-item" v-if="stepItems.length > 1">
                <v-icon @click="setDeleteElement(stepItem.id)" title="Move Down"
                  color="secondary-red">mdi-delete</v-icon>
              </div>
              <div class="form-step__item__action-item" v-if="stepItems.length > 1">
                <v-icon v-if="!isFirstStepItem(stepItem)" @click="moveStepItem(true, stepItem)" title="Move Up"
                  color="grey-darken-4">mdi-arrow-up</v-icon>
                <v-icon v-if="!isLastStepItem(stepItem)" @click="moveStepItem(false, stepItem)" title="Move Down"
                  color="grey-darken-4">mdi-arrow-down</v-icon>
              </div>
            </div>
            <form-step-textbox-element :element="getElement(stepItem.id)"
              v-if="getElement(stepItem.id).type === formStepElementTypes.TEXTBOX" />
            <div class="form-step__item__action-bottom">
              <div class="form-step__item__action-item add-item">
                <v-icon @click="addItem(stepItem.order)" title="Add New Element"
                  color="yellow-darken-2">mdi-plus-circle</v-icon>
              </div>
            </div>
          </div>
          <!-- QUESTION -->
          <div class="form-step__item-question" v-else>
            <div class="form-step__item__action-top">
              <div class="form-step__item__action-item" v-if="stepItems.length > 1">
                <v-icon @click="setDeleteQuestion(stepItem.id)" title="Delete Question"
                  color="secondary-red">mdi-delete</v-icon>
              </div>
              <span class="mt-1" :class="stepItems.length <= 1 ? 'duplicate' : 'duplicate-question'">
                <v-icon @click="duplicateQuestion(stepItem.id, stepItem.order)" title="Duplicate Question"
                  color="grey-darken-4">mdi-content-copy</v-icon>
              </span>&nbsp;
              <div class="form-step__item__action-item" v-if="stepItems.length > 1">
                <v-icon v-if="!isFirstStepItem(stepItem)" @click="moveStepItem(true, stepItem)" title="Move Up"
                  color="grey-darken-4">mdi-arrow-up</v-icon>
                <v-icon v-if="!isLastStepItem(stepItem)" @click="moveStepItem(false, stepItem)" title="Move Down"
                  color="grey-darken-4">mdi-arrow-down</v-icon>
              </div>
            </div>
            <form-question :key="stepItem.id"
              v-bind:index="stepItem.order - getItemsCountBefore('element', stepItem.id)" v-bind:step-id="step.id"
              v-bind:question-id="stepItem.id"></form-question>
            <div class="form-step__item__action-bottom">
              <div class="form-step__item__action-item add-item">
                <v-icon @click="addItem(stepItem.order)" title="Add New Element"
                  color="yellow-darken-2">mdi-plus-circle</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- STEP FOOTER -->
      <div class="step-foot">
        <div class="text-left" v-if="id === 1 && steps.length > 1 && showPartialLeads">
          <v-alert class="pa-5" type="info" variant="tonal" rounded="md" density="compact" icon="mdi-information">
            <p class="text-black mb-0"> <strong>Partial Lead Notification:</strong> Partial lead collection is activated
              for this form. A notification or opt-in will be present to your form
              visitor at this point of the form. Edit display settings or turn feature off via the Form Settings or
              Partial leads section.
            </p>
          </v-alert>
        </div>
        <div v-if="isAutoNavigable">
          <v-switch inset :model-value="!!step.autoNavigation" @update:modelValue="updateStepAutoNavigation($event)"
            label="Activate Auto Navigation" hide-details color="secondary-red">
          </v-switch>
        </div>
        <div v-if="!isLastStep">
          <v-row>
            <v-col class="text-left" cols="2">
            <h5 class="font-weight-bold">After Step {{ currentStepIndex }}</h5>
          </v-col>
          <v-col cols="4" class="pa-0 ml-n6">
            <v-select class="jumpToStep" :center-affix="true" active hide-details persistent-placeholder placeholder="Jump to Next Step"  :items="stepsMap" item-title="label"  variant="outlined"
             density="comfortable" :model-value="getStepJump" return-object
            @update:modelValue="updateStepJump($event)"></v-select>
          </v-col>
          </v-row>
        </div>
      </div>
      <!-- STEP BOTTOM ACTIONS -->
      <div class="form-step__action-bottom">
        <div class="form-step__action-item add-item">
          <v-icon @click="addStep()" title="Add New Step" color="primary-red">mdi-plus-circle</v-icon>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <!-- Delete step modal-->
    <v-dialog v-model="showDeleteStepModal" id="deleteStepModal" ref="deleteStepModal"
      @close="showDeleteStepModal = false" rounded="sm" max-width="500">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class=" text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-delete</v-icon>
          </v-avatar>
        </div>
        <!-- Delete Text -->
        <v-card-text class="text-center">
          <h3 class="font-weight-bold mb-0">Delete Step</h3>
          <v-alert v-if="inLogicJumps" border type="warning" variant="tonal" rounded="md" density="compact"
            icon="mdi-information">
            <p class="text-black text-left mb-0">Please remove all the references of this step from the logic jumps in
              previous steps.</p>
          </v-alert>
          <v-alert v-else border type="error" variant="tonal" rounded="md" density="compact" icon="mdi-information">
            <p class="text-black text-left mb-0">You are about to delete step {{ (getStepIndex(id) + 1) }}. Are you
              sure?</p>
          </v-alert>
        </v-card-text>
        <!-- Buttons -->
        <v-card-actions class="d-flex justify-center pt-0" v-if="!inLogicJumps">
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-25 mr-1" variant="flat"
            @click="showDeleteStepModal = false">
            Cancel
          </v-btn>
          <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize w-25 ml-1" variant="flat"
            @click="deleteStep()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add new element/question modal-->
    <v-dialog v-model="showItemsModal" id="itemsModal" ref="itemsModal" @close="showItemsModal = false" rounded="sm"
      max-width="500">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class=" text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-playlist-plus</v-icon>
          </v-avatar>
        </div>
        <!-- Text -->
        <v-card-text class="text-center">
          <h3 class="font-weight-bold">Add New Element</h3>
        </v-card-text>
        <!-- Buttons -->
        <v-card-actions class="d-flex justify-center pt-0">
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-33 mr-1" variant="flat"
            prepend-icon="mdi-help-circle-outline" size="large" @click.stop="addQuestion()">
            Question
          </v-btn>
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-33 ml-1" variant="flat"
            prepend-icon="mdi-text-shadow" size="large" @click.stop="addElement()">
            Textbox
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete question modal-->
    <v-dialog v-model="showDeleteQuestionModal" id="deleteQuestionModal" ref="deleteQuestionModal"
      @close="showDeleteQuestionModal = false" rounded="sm" max-width="500">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class=" text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-delete</v-icon>
          </v-avatar>
        </div>
        <!-- Delete Text -->
        <v-card-text class="text-center">
          <h3 class="font-weight-bold">Delete Question</h3>
          <v-alert border type="error" variant="tonal" rounded="md" density="compact" icon="mdi-information">
            <p class="text-black text-left mb-0">Are you sure to delete this question?</p>
          </v-alert>
        </v-card-text>
        <!-- Buttons -->
        <v-card-actions class="d-flex justify-center pt-0">
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-25 mr-1" variant="flat"
            @click="showDeleteQuestionModal = false">
            Cancel
          </v-btn>
          <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize w-25 ml-1" variant="flat"
            @click="deleteQuestion()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete element modal-->
    <v-dialog v-model="showDeleteElementModal" id="deleteElementModal" ref="deleteElementModal"
      @close="showDeleteElementModal = false" rounded="sm" max-width="500">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class=" text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-delete</v-icon>
          </v-avatar>
        </div>
        <!-- Delete Text -->
        <v-card-text class="text-center">
          <h3 class="font-weight-bold">Delete Textbox</h3>
          <v-alert border type="error" variant="tonal" rounded="md" density="compact" icon="mdi-information">
            <p class="text-black text-left mb-0">Are you sure to delete this element?</p>
          </v-alert>
        </v-card-text>
        <!-- Buttons -->
        <v-card-actions class="d-flex justify-center pt-0">
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-25 mr-1" variant="flat"
            @click="showDeleteElementModal = false">
            Cancel
          </v-btn>
          <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize w-25 ml-1" variant="flat"
            @click="deleteElement()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import * as actionTypes from '../store/action-types'
import _ from 'lodash'
import draggable from 'vuedraggable'
import FormQuestion from './FormQuestion'
import { mapGetters } from 'vuex'
import TextBoxElement from '@/components/form-builder/Elements/TextBoxElement'
import { debounce } from 'lodash'
import AppDialog from './AppDialog.vue'

export default {
  props: {
    id: Number,
    index: Number
  },

  components: {
    'form-question': FormQuestion,
    'draggable': draggable,
    'form-step-textbox-element': TextBoxElement,
    AppDialog,
  },

  data: function () {
    return {
      formScope: 'form_builder',
      currentItemNumber: -1,
      currentQuestionId: -1,
      currentElementId: -1,
      formId: null,
      showPartialLeads: null,
      showDeleteElementModal: false,
      showDeleteStepModal: false,
      showDeleteQuestionModal: false,
      showItemsModal: false
    }
  },
  mounted: function () {
    this.formId = this.$route.params.id
    this.fetchFormSetting()
  },
  methods: {
    fetchFormSetting: function () {
      this.$store.dispatch(actionTypes.FORM_FETCH_SETTING, {
        formId: this.formId
      }).then((response) => {
        let setting = response?.data?.data
        this.$store.dispatch(actionTypes.FORM_FETCH_SETTING_SUCCESS, {
          formId: this.formId,
          setting: setting
        })
        this.showPartialLeads = setting.enabled
      }, (response) => {
        this.$store.dispatch(actionTypes.FORM_FETCH_SETTING_ERROR)
      })
    },
    addItem: function (number) {
      this.currentItemNumber = number
      this.openDialog('itemsModal')
    },
    addQuestion: function () {
      this.updateStepItemsOrder(1)
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QUESTION, {
        'question': {
          'id': ++this.$store.state.formBuildState.lastQuestionId,
          'number': this.currentItemNumber + 1,
          'stepId': this.id,
          'type': '',
          'required': true,
          'placeholder': '',
          'title': '',
          'description': '',
          'choices': [
            { id: 1, label: 'Choice 1', order: 1, description: '', icon: '', image: '' },
            { id: 2, label: 'Choice 2', order: 2, description: '', icon: '', image: '' },
            { id: 3, label: 'Choice 3', order: 3, description: '', icon: '', image: '' }
          ],
          restrictEmailFields: [
            { id: 1, email: '', order: 1, allow: 1 }
          ],
          'choicesValues': [0, 0, 0],
          'enabled': true,
          'hide_title': false
        }
      }).then(() => {
        /* this.$validator
          .validate(
            `${this.formScope}.question-type-${this.$store.state.formBuildState.lastQuestionId}`
          ) */
      })
      this.closeDialog('itemsModal')
    },
    addElement: function () {
      this.updateStepItemsOrder(1)
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_ELEMENT, {
        'element': {
          'id': ++this.$store.state.formBuildState.lastElementId,
          'number': this.currentItemNumber + 1,
          'stepId': this.id,
          'type': this.formStepElementTypes.TEXTBOX,
          'content': 'This is a textbox.'
        }
      })
      this.closeDialog('itemsModal')
    },
    deleteStep: function () {
      this.closeDialog('deleteStepModal')
      const debouncedDispatch = debounce(() => {
        this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_STEP, {
          'stepId': this.id
        })
      }, 100)
      debouncedDispatch()
    },
    openDialog: function (id) {
      switch (id) {
        case 'deleteQuestionModal': this.showDeleteQuestionModal = true; break;
        case 'deleteElementModal': this.showDeleteElementModal = true; break;
        case 'itemsModal': this.showItemsModal = true; break;
        case 'deleteStepModal': this.showDeleteStepModal = true; break;
      }
    },
    closeDialog: function (id) {
      switch (id) {
        case 'deleteQuestionModal': this.showDeleteQuestionModal = false; break;
        case 'deleteElementModal': this.showDeleteElementModal = false; break;
        case 'itemsModal': this.showItemsModal = false; break;
        case 'deleteStepModal': this.showDeleteStepModal = false; break;
      }
    },
    getStepIndex: function (stepId) {
      let sIndex = 0
      for (let step of this.steps) {
        if (step.id === stepId) {
          return sIndex
        }
        sIndex++
      }
    },
    updateStepJump: function (step) {
      if (step) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_STEP_JUMP, {
          stepId: this.step.id,
          step: step.value
        })
      }
    },
    updateStepAutoNavigation: function (value) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_STEP_AN, {
        stepId: this.step.id,
        autoNavigation: value ? 1 : 0
      })
    },
    updateStepItemsOrder: function (increment) {
      for (let stepItem of this.stepItems) {
        if (stepItem.order <= this.currentItemNumber) {
          continue
        }
        if (stepItem.type === 'element') {
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_ENUMBER, {
            stepId: this.step.id,
            elementId: stepItem.id,
            number: stepItem.order + increment
          })
        } else if (stepItem.type === 'question') {
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QNUMBER, {
            stepId: this.step.id,
            questionId: stepItem.id,
            number: stepItem.order + increment
          })
        }
      }
    },
    getElement: function (elementId) {
      for (let element of this.elements) {
        if (element.id === elementId) {
          return element
        }
      }
    },
    getQuestion: function (questionId) {
      for (let question of this.questions) {
        if (question.id === questionId) {
          return question
        }
      }
    },
    getQuestionIndex: function (questionId) {
      let qIndex = 0
      for (let question of this.questions) {
        if (question.id === questionId) {
          return qIndex
        }
        qIndex++
      }
    },
    getItemsCountBefore: function (type, id) {
      let count = 0
      for (let stepItem of this.stepItems) {
        if (stepItem.type === type) {
          count++
        }
        if (stepItem.id === id) {
          return count
        }
      }
    },
    moveStepItem: function (up, item) {
      let prevItem = null
      let nextItem = null
      for (let stepItem of this.stepItems) {
        if (stepItem.order === item.order - 1) {
          prevItem = Object.assign({}, stepItem)
        }
        if (stepItem.order === item.order + 1) {
          nextItem = Object.assign({}, stepItem)
        }
      }
      if (up && prevItem) {
        this.updateStepItemNumber(prevItem, item.order)
        this.updateStepItemNumber(item, prevItem.order)
      }
      if (!up && nextItem) {
        this.updateStepItemNumber(nextItem, item.order)
        this.updateStepItemNumber(item, nextItem.order)
      }
    },
    updateStepItemNumber: function (item, number) {
      if (item.type === 'element') {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_ENUMBER, {
          stepId: this.step.id,
          elementId: item.id,
          number: number
        })
      } else if (item.type === 'question') {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QNUMBER, {
          stepId: this.step.id,
          questionId: item.id,
          number: number
        })
      }
    },
    setDeleteQuestion: function (questionId) {
      this.currentQuestionId = questionId
      this.openDialog('deleteQuestionModal')
    },
    deleteQuestion: function () {
      this.currentItemNumber = this.getQuestion(this.currentQuestionId).number
      this.updateStepItemsOrder(-1)
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_Q, {
        'stepId': this.step.id,
        'questionId': this.currentQuestionId
      }).then((result) => {
        this.currentQuestionId = -1
        this.closeDialog('deleteQuestionModal')
      })
    },
    setDeleteElement: function (elementId) {
      this.currentElementId = elementId
      this.openDialog('deleteElementModal')
    },
    deleteElement: function () {
      this.currentItemNumber = this.getElement(this.currentElementId)?.number
      this.updateStepItemsOrder(-1)
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_E, {
        'stepId': this.step.id,
        'elementId': this.currentElementId
      }).then((result) => {
        this.currentElementId = -1
        this.closeDialog('deleteElementModal')
      })
    },
    isFirstStepItem: function (stepItem) {
      return stepItem.order === 1
    },
    isLastStepItem: function (stepItem) {
      return this.stepItems.length === stepItem.order
    },
    duplicateQuestion: function (questionId, number) {
      this.currentItemNumber = number
      this.updateStepItemsOrder(1)
      let duplicate = null
      for (let question of this.questions) {
        if (question.id === questionId) {
          duplicate = _.cloneDeep(question)
          duplicate.id = ++this.$store.state.formBuildState.lastQuestionId
          duplicate.title = 'Duplicate ' + duplicate.title
          duplicate.number = this.currentItemNumber + 1
          delete duplicate.dbId
          this.$store.dispatch(actionTypes.FORMBUILDER_DUPLICATE_QUESTION, {
            'duplicate': duplicate
          })
        }
      }
    },
    addStep: function () {
      let stepId = ++this.$store.state.formBuildState.lastStepId
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_STEP, {
        'position': this.index + 1,
        'step': {
          id: stepId,
          autoNavigation: false,
          'questions': [{
            'id': ++this.$store.state.formBuildState.lastQuestionId,
            'number': 1,
            'stepId': stepId,
            'type': '',
            'required': true,
            'placeholder': '',
            'title': '',
            'description': '',
            'choices': [
              { id: 1, label: 'Choice 1', order: 1, description: '', icon: '', image: '' },
              { id: 2, label: 'Choice 2', order: 2, description: '', icon: '', image: '' },
              { id: 3, label: 'Choice 3', order: 3, description: '', icon: '', image: '' }
            ],
            restrictEmailFields: [
              { id: 1, email: '', order: 1, allow: 1 }
            ],
            'choicesValues': [0, 0, 0],
            'enabled': true,
            'hide_title': false
          }],
          'elements': []
        }
      })
    }
  },

  computed: {
    ...mapGetters([
      'questionTypesMap',
      'formStepElementTypes',
      'formSetting'
    ]),
    elements: function () {
      return this.step['elements'] || []
    },
    questions: {
      get() {
        if (this.step) {
          return this.step.questions
        } else {
          return []
        }
      },
      set(questions) {
        if (questions.length > 0) {
          this.$store.dispatch(
            actionTypes.FORMBUILDER_UPDATE_STEPQ,
            {
              stepId: this.id,
              questions: questions
            }
          )
        }
      }
    },
    inLogicJumps: function () {
      let steps = this.steps.filter(step => step.id !== this.id)
      for (let step of steps) {
        for (let question of step.questions) {
          if (
            question.type === this.questionTypesMap.SINGLE_CHOICE ||
            question.type === this.questionTypesMap.MULTIPLE_CHOICE
          ) {
            if (question.jumps) {
              for (let jump of question.jumps) {
                if (jump.step === this.getStepIndex(this.id) + 1) {
                  return true
                }
              }
            }
          }
        }
      }
      return false
    },
    step: function () {
      return this.$store.getters.getStep(this.id)
    },
    steps: function () {
      return this.$store.state.formBuildState.steps || []
    },
    questionDragOptions: function () {
      return {
        group: {
          name: 'form-question',
          pull: function (to, from) {
            return from.el.children.length > 1
          }
        },
        handle: '.question-handle',
        scrollSensitivity: 100,
        scrollSpeed: 5
      }
    },
    stepsMap: function () {
      let stepsMap = this.steps
        .filter(step => step.id !== this.step.id)
        .map((step, index) => {
          let stepIndex = Number(this.getStepIndex(step.id) + 1)
          let label = 'Jump To Step ' + stepIndex.toString()
          if (stepIndex - 1 === this.currentStepIndex) {
            label = 'Jump To Next Step'
          }
          return {
            label: label,
            value: stepIndex
          }
        })
      stepsMap.push({ label: 'Jump To End', value: -1 })
      return stepsMap
    },
    getStepJump: function () {
      if (!this.step.jump) {
        return null
      }
      let stepIndex = this.step.jump.step
      let label = 'Jump To Step ' + this.step.jump.step
      if (stepIndex - 1 === this.currentStepIndex) {
        label = 'Jump To Next Step'
      } else if (this.step.jump.step === -1) {
        label = 'Jump To End'
      }
      return { label: label, value: this.step.jump.step }
    },
    currentStepIndex: function () {
      return Number(this.getStepIndex(this.step.id) + 1)
    },
    isLastStep: function () {
      return this.steps.length === this.currentStepIndex
    },
    isAutoNavigable: function () {
      if (this.isLastStep) {
        return false
      }

      for (let question of this.step.questions) {
        if (question.type !== this.questionTypesMap.SINGLE_CHOICE) {
          return false
        }
      }

      return true
    },
    stepItems: function () {
      let items = []
      for (let question of this.questions) {
        items.push({ type: 'question', id: question['id'], order: question['number'] })
      }
      for (let element of this.elements) {
        items.push({ type: 'element', id: element['id'], order: element['number'] })
      }
      items = items.sort(function (a, b) {
        return a.order - b.order
      })
      return items
    }
  }
}
</script>
